<template>
  <div class="page inPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">DatePicker</div>
    </div>
    <div class="pageBody">
      <lee-date-picker
          v-model:show="show1"
          v-model="currentDate1"
          title="选择日期"
          @onConfirm="onConfirm1"
      />
      <lee-date-picker
          v-model:show="show2"
          v-model="currentDate2"
          title="选择日期"
          @onConfirm="onConfirm2"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
      />
      <lee-date-picker
          v-model:show="show3"
          title="选择日期"
          @onOpen="onOpen"
          @onClose="onClose"
          @onConfirm="onConfirm"
          @onCancel="onCancel"
          @onChange="onChange"
      />
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="基础用法" is-link @click="show1 = true" :footer="result1"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="可选范围" inset>
        <lee-cell title="可选范围" is-link @click="show2 = true" :footer="result2"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="监听事件" inset>
        <lee-cell title="监听事件" is-link @click="show3 = true" :footer="result3"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {showToast} from '@/leeplus';
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
const show1 = ref(false)
const show2 = ref(false)
const show3 = ref(false)
const result1 = ref('')
const result2 = ref('')
const result3 = ref('')
const currentDate1 = ref(['2011', '02', '04']);
const currentDate2 = ref(['2011', '02', '04']);
const minDate = new Date(2020, 10, 10)
const maxDate = new Date(2025, 8, 10)


//事件
const getResult = (rs) => {
    let textArr = rs.text
    let result = ''
    let fh = '/'
    for (let i = 0; i < textArr.length; i++) {
        if (i === textArr.length - 1) {
            fh = ''
        }
        result += textArr[i] + fh
    }
    return result
}
const onConfirm1 = (rs) => {
    result1.value = getResult(rs)
}
const onConfirm2 = (rs) => {
    result2.value = getResult(rs)
}
const formatter = ref(['年', '月', '日'])

const onOpen = () => {
    showToast('打开')
}
const onClose = () => {
    showToast('关闭')
}
const onConfirm = (rs) => {
    showToast('当前选中值：' + rs.value)
    result3.value = getResult(rs)
}

const onCancel = () => {
    showToast('取消')
}
const onChange = (rs) => {
    showToast('当前选中值：' + rs.value)
}


</script>
<style>


</style>
